<template>
    <div>
        <div
            class="col-12 mb-4"
        >
            <label for="select-search-type" class="form-label">Suchart auswählen</label>
            <select
                name="select_search_type"
                v-model="organisationSearchType"
                id="select-search-type"
                class="form-select form-select-lg"
            >
                <option value="zip">Umkreissuche über die Postleitzahl</option>
                <option value="city">Über den Ort eines Veranstalters suchen</option>
                <option value="name">Über den Namen eines Veranstalters suchen</option>
            </select>
        </div>
        <!--    Start Suche über Name-->
        <div class="input-group has-validation" v-if="width > 1000 && organisationSearchType == 'name'">
            <input
                list="name_datalist_id"
                class="form-control col-12 form-control-lg"
                autocomplete="off"
                name="name"
                v-model="nameValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                aria-errormessage="name_datalist_response_id"
                aria-label="Du bekommst hier eine Liste mit möglichen Namen. Durch deine Eingabe kannst du die Liste filtern. "
                id="name_datalist_input_id"
                placeholder="Name eingeben"
            />
            <div
                id="name_datalist_response_id"
                class="invalid-feedback"
            >
                Wir haben keinen Veranstalter mit einem passenden Namen gespeichert.
            </div>
            <datalist
                id="name_datalist_id"
            >
                <option v-for="opportunity in opportunities">
                    {{ opportunity }}
                </option>
            </datalist>
            <div class="form-text col-12">
                Hier kannst über den Namen des Veranstalters suchen. Wenn wir den Veranstalter gespeichert, haben wirst
                du eine Liste mit Vorschlägen sehen aus denen du auswählen kannst.
            </div>
        </div>
        <div class="input-group has-validation" v-else-if="width <= 1000 && organisationSearchType == 'name'">
            <input
                class="form-control form-control-lg col-12"
                autocomplete="off"
                name="name"
                v-model="nameValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                aria-errormessage="name_list_response_id"
                aria-label="Du bekommst hier eine Liste mit möglichen Namen. Durch deine Eingabe kannst du die Liste filtern. "
                id="name_list_input_id"
                placeholder="Name eingeben"
            />
            <div
                id="name_list_response_id"
                class="invalid-feedback"
            >
                Wir haben keinen Veranstalter mit einem passenden Namen gespeichert.
            </div>
            <div class="form-text col-12">
                Hier kannst über den Namen des Veranstalters suchen. Wenn wir den Veranstalter gespeichert, haben wirst
                du eine Liste mit Vorschlägen sehen aus denen du auswählen kannst.
            </div>
            <ul
                v-if="showOpportunities == true"
                id="name_list_id"
                class="col-12"
            >
                <li
                    v-for="opportunity in opportunities"
                >
                    <span @click="setSelectedValue(opportunity)">{{ opportunity }}</span>
                </li>
            </ul>
        </div>
        <!--    Ende Suche über Name-->

        <!--    Start Suche über Ort-->
        <div class="input-group has-validation" v-else-if="width > 1000 && organisationSearchType == 'city'">
            <input
                list="city_datalist_id"
                class="form-control col-12 form-control-lg"
                autocomplete="off"
                name="city"
                v-model="cityValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                aria-errormessage="city_datalist_response_id"
                aria-label="Du bekommst hier eine Liste mit möglichen Orten. Durch deine Eingabe kannst du die Liste filtern. "
                id="city_datalist_input_id"
                placeholder="Ort eingeben"
            />
            <div
                id="city_datalist_response_id"
                class="invalid-feedback"
            >
                Wir haben keinen passenden Ort zu deinen Eingaben gespeichert.
            </div>
            <datalist
                id="city_datalist_id"
            >
                <option v-for="opportunity in opportunities">
                    {{ opportunity }}
                </option>
            </datalist>
            <div class="form-text col-12">
                Hier kannst du nach allen Orten suchen die wir für Veranstalter gespeichert haben.
                Wenn wir einen passenden Ort zu deinen Eingaben gespeichert haben, wirst
                du eine Liste mit Vorschlägen sehen aus denen du auswählen kannst.
            </div>
        </div>
        <div class="input-group has-validation" v-else-if="width <= 1000 && organisationSearchType == 'city'">
            <input
                class="form-control form-control-lg col-12"
                autocomplete="off"
                name="city"
                v-model="cityValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                aria-errormessage="ort_list_response_id"
                aria-label="Du bekommst hier eine Liste mit möglichen Orten. Durch deine Eingabe kannst du die Liste filtern. "
                id="ort_list_input_id"
                placeholder="Ort eingeben"
            />
            <div
                id="ort_list_response_id"
                class="invalid-feedback"
            >
                Wir haben keinen passenden Ort zu deinen Eingaben gespeichert.
            </div>
            <div class="form-text col-12">
                Hier kannst du nach allen Orten suchen die wir für Veranstalter gespeichert haben.
                Wenn wir einen passenden Ort zu deinen Eingaben gespeichert haben, wirst
                du eine Liste mit Vorschlägen sehen aus denen du auswählen kannst.
            </div>
            <ul
                v-if="showOpportunities == true"
                id="ort_list_id"
                class="col-12"
            >
                <li
                    v-for="opportunity in opportunities"
                >
                    <span @click="setSelectedValue(opportunity)">{{ opportunity }}</span>
                </li>
            </ul>
        </div>
        <!--    Ende Suche über Ort-->

        <!--    Start Suche über Postleitzahl-->
        <div class="input-group has-validation" v-else-if="width > 1000 && organisationSearchType == 'zip'">
            <input
                list="zip_datalist_id"
                class="form-control col-12 form-control-lg"
                autocomplete="off"
                name="zip"
                v-model="zipValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                aria-errormessage="zip_datalist_response_id"
                aria-label="Du bekommst hier eine Liste allen Postleitzahlen aus Deutschland. Durch deine Eingabe kannst du die Liste filtern. "
                id="zip_datalist_input_id"
                placeholder="PLZ eingeben"
            />
            <div
                id="zip_datalist_response_id"
                class="invalid-feedback"
            >
                Es gibt keine passende Postleitzahl zu deiner Eingabe.
            </div>
            <datalist
                id="zip_datalist_id"
            >
                <option v-for="opportunity in opportunities" :value="opportunity.zip">
                    {{ opportunity.display_name }}
                </option>
            </datalist>
            <div class="form-text col-12">
                Hier kannst du eine Postleitzahl eingeben. Nach deiner Eingabe wirst
                du eine Liste mit Vorschlägen sehen aus denen du auswählen kannst.
            </div>
        </div>
        <div class="input-group has-validation" v-else-if="width <= 1000 && organisationSearchType == 'zip'">
            <input
                class="form-control form-control-lg col-12"
                autocomplete="off"
                name="zip"
                v-model="zipValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                aria-errormessage="zip_list_response_id"
                aria-label="Nachdem du 2 Zahlen eingegeben hast, bekommst du eine Liste mit allen Postleitzahlen aus
                            Deutschland. Durch weitere Eingabe kannst du die Liste filtern."
                id="zip_list_input_id"
                placeholder="PLZ eingeben"
            />
            <div
                id="zip_list_response_id"
                class="invalid-feedback"
            >
                Es gibt keine passende Postleitzahl zu deiner Eingabe.
            </div>
            <div class="form-text col-12">
                Hier kannst du eine Postleitzahl eingeben. Nach deiner Eingabe wirst
                du eine Liste mit Vorschlägen sehen aus denen du auswählen kannst.
            </div>
            <ul
                v-if="showOpportunities == true"
                id="zip_list_id"
                class="col-12"
            >
                <li
                    v-for="opportunity in opportunities"
                >
                    <span @click="setSelectedValue(opportunity.zip)">{{ opportunity.display_name }}</span>
                </li>
            </ul>
        </div>
        <!--    Ende Suche über Postleitzahl-->
    </div>
</template>

<script>
export default {
    props: {
        nameValue: String,
        cityValue: String,
        zipValue: String,

        handingOverSearchType: String,

        nameRoute: String,
        cityRoute: String,
        zipRoute: String,
    },
    data() {
        return {
            organisationSearchType: this.handingOverSearchType,

            activeRoute: String,

            inputNotExistent: false,
            opportunities: null,

            width: 0,
            height: 0,

            showOpportunities: true,
        };
    },
    watch: {
        opportunities() {
            this.inputNotExistent = (this.opportunities.length === 0);
        },
        organisationSearchType() {
            this.setActiveRoute()
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.setActiveRoute();
    },
    methods: {
        fetchOpportunities() {
            if (
                // Das Formular bricht ab, wenn zu viele PLZs in der Liste sind
                (this.organisationSearchType == 'zip' && this.zipValue.length < 2)
            ) {
                return;
            }

            axios.get(this.activeRoute, {
                params: {
                    filter: this.getActiveValue(),
                }
            }).then(response => {
                // console.log('Werte geholt');
                // console.log(this.activeRoute);
                // console.log(this.getActiveValue());
                // console.log(response.data);
                this.showOpportunities = true;
                this.opportunities = response.data;
            })
        },
        setSelectedValue(selected) {

            if (this.organisationSearchType == 'name') {
                this.nameValue = selected;
            }

            if (this.organisationSearchType == 'city') {
                this.cityValue = selected;
            }

            if (this.organisationSearchType == 'zip') {
                this.zipValue = selected;
            }


            this.showOpportunities = false;
        },
        setActiveRoute() {
            if (this.organisationSearchType == 'name') {
                this.activeRoute = this.nameRoute;
            }
            if (this.organisationSearchType == 'city') {
                this.activeRoute = this.cityRoute;
            }
            if (this.organisationSearchType == 'zip') {
                this.activeRoute = this.zipRoute;
            }
        },
        getActiveValue() {
            // console.log('Aktiver Suchfilter ermitteln');
            // console.log(this.organisationSearchType);
            if (this.organisationSearchType == 'name') {
                return this.nameValue;
            }

            if (this.organisationSearchType == 'city') {
                return this.cityValue;
            }

            if (this.organisationSearchType == 'zip') {
                return this.zipValue;
            }

            return '';
        },
        handleResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        },
    },
    mounted() {
    }
};
</script>

<style>
#name_list_id, #ort_list_id, #zip_list_id {
    list-style-type: none;
    padding-top: 1rem;
    padding-left: 1rem;
}

#name_list_id li, #ort_list_id li, #zip_list_id li {
    padding: 0.5rem 0.5rem 0.5rem 0;
    border-bottom: 1px solid grey;
}
</style>
