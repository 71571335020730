<template>
    <span
        :id="spanId"
        class="d-inline-block"
        tabindex="0"
        data-bs-toggle="tooltip"
        title="Text kopiert"
    >
    <button
        class="btn btn-outline-dark copy-btn badge rounded-pill"
        :data-clipboard-text="toCopy"
        :title="description"
        :id="btnId"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-clipboard"
            viewBox="0 0 16 16"
            aria-hidden="true"
        >
            <path
                d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
            />
            <path
                d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
            />
        </svg>
        Kopieren
    </button>
</span>
</template>

<script>
export default {
    props: {
        toCopy: String,
        description: String,
        id: String,
    },
    data() {
        return {
            spanId: 'span-' + this.id,
            btnId: 'btn-' + this.id,
        };
    },
    watch: {},
    methods: {},
    mounted() {
        console.log('mounted');
        let clipboard = new ClipboardJS('#' + this.btnId, {
            container: document.getElementById(this.spanId)
        });

        let tooltip = new bootstrap.Tooltip(document.getElementById(this.spanId));
        tooltip.disable();

        clipboard.on('success', function (e) {
            tooltip.enable();
            tooltip.show();
            tooltip.disable();
            e.clearSelection();
        });

        clipboard.on('error', function (e) {
            console.error('Action:', e.action);
            console.error('Trigger:', e.trigger);
        });
    }
};
</script>

<style>

</style>
