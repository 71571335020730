<template>
    <div class="row">
        <div
            class="col-md-6 mb-4"
        >
            <label for="select-search-type" class="form-label">Suchart auswählen</label>
            <select
                name="select_search_type"
                v-model="eventSearchType"
                id="select-search-type"
                class="form-select form-select-lg"
            >
                <option value="zip">Umkreissuche über die Postleitzahl:</option>
                <option value="city">Über den Ort suchen:</option>
            </select>
        </div>

        <!--    Start Suche über Ort-->
        <div class="col-md-6" v-if="width > 1000 && eventSearchType == 'city'">
            <label class="form-label">Ort eingeben</label>
            <div class="input-group has-validation">
                <input
                    list="city_datalist_id"
                    class="form-control col-12 form-control-lg"
                    autocomplete="off"
                    name="city"
                    v-model="cityValue"
                    @keyup="fetchOpportunities"
                    @click="fetchOpportunities"
                    :class="{ 'is-invalid': inputNotExistent }"
                    aria-errormessage="city_datalist_response_id"
                    aria-label="Du bekommst hier eine Liste mit möglichen Orten. Durch deine Eingabe kannst du die Liste filtern. "
                    id="city_datalist_input_id"
                    placeholder="Ort eingeben"
                />
                <div
                    id="city_datalist_response_id"
                    class="invalid-feedback"
                >
                    Wir haben keinen entsprechenden Ort gespeichert.
                </div>
                <datalist
                    id="city_datalist_id"
                >
                    <option v-for="opportunity in opportunities">
                        {{ opportunity }}
                    </option>
                </datalist>
            </div>
        </div>

        <div class="input-group has-validation col-6" v-else-if="width <= 1000 && eventSearchType == 'city'">
            <input
                class="form-control form-control-lg col-12"
                autocomplete="off"
                name="city"
                v-model="cityValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                aria-errormessage="ort_list_response_id"
                aria-label="Du bekommst hier eine Liste mit möglichen Orten. Durch deine Eingabe kannst du die Liste filtern. "
                id="ort_list_input_id"
                placeholder="Ort eingeben"
            />
            <div
                id="ort_list_response_id"
                class="invalid-feedback"
            >
                Wir haben keinen entsprechenden Ort gespeichert.
            </div>
            <ul
                v-if="showOpportunities == true"
                id="ort_list_id"
                class="col-12"
            >
                <li
                    v-for="opportunity in opportunities"
                >
                    <span @click="setSelectedValue(opportunity)">{{ opportunity }}</span>
                </li>
            </ul>
        </div>
        <!--    Ende Suche über Ort-->

        <!--    Start Suche über Postleitzahl-->
        <div class="col-md-6" v-else-if="width > 1000 && eventSearchType == 'zip'">
            <label class="form-label">Postleitzahl eingeben</label>
            <div class="input-group has-validation">
                <input
                    list="zip_datalist_id"
                    class="form-control col-12 form-control-lg"
                    autocomplete="off"
                    name="zip"
                    v-model="zipValue"
                    @keyup="fetchOpportunities"
                    @click="fetchOpportunities"
                    :class="{ 'is-invalid': inputNotExistent }"
                    aria-errormessage="zip_datalist_response_id"
                    aria-label="Du bekommst hier eine Liste allen Postleitzahlen aus Deutschland. Durch deine Eingabe kannst du die Liste filtern. "
                    id="zip_datalist_input_id"
                    placeholder="PLZ eingeben"
                />
                <div
                    id="zip_datalist_response_id"
                    class="invalid-feedback"
                >
                    Es gibt keine passende Postleitzahl.
                </div>
                <datalist
                    id="zip_datalist_id"
                >
                    <option v-for="opportunity in opportunities" :value="opportunity.zip">
                        {{ opportunity.display_name }}
                    </option>
                </datalist>
            </div>
        </div>
        <div class="input-group has-validation col-6" v-else-if="width <= 1000 && eventSearchType == 'zip'">
            <input
                class="form-control form-control-lg col-12"
                autocomplete="off"
                name="zip"
                v-model="zipValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                aria-errormessage="zip_list_response_id"
                aria-label="Nachdem du 2 Zahlen eingegeben hast, bekommst du eine Liste mit allen Postleitzahlen aus
                            Deutschland. Durch weitere Eingabe kannst du die Liste filtern."
                id="zip_list_input_id"
                placeholder="PLZ eingeben"
            />
            <div
                id="zip_list_response_id"
                class="invalid-feedback"
            >
                Es gibt keine passende Postleitzahl.
            </div>
            <ul
                v-if="showOpportunities == true"
                id="zip_list_id"
                class="col-12"
            >
                <li
                    v-for="opportunity in opportunities"
                >
                    <span @click="setSelectedValue(opportunity.zip)">{{ opportunity.display_name }}</span>
                </li>
            </ul>
        </div>
        <!--    Ende Suche über Postleitzahl-->
    </div>
</template>

<script>
export default {
    props: {
        cityValue: String,
        zipValue: String,

        handingOverSearchType: String,

        cityRoute: String,
        zipRoute: String,
        // trips oder locals
        type: String,
    },
    data() {
        return {
            eventSearchType: this.handingOverSearchType,

            activeRoute: String,

            inputNotExistent: false,
            opportunities: null,

            width: 0,
            height: 0,

            showOpportunities: true,
        };
    },
    watch: {
        opportunities() {
            this.inputNotExistent = (this.opportunities.length === 0);
        },
        eventSearchType() {
            this.setActiveRoute()
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.setActiveRoute();
    },
    methods: {
        fetchOpportunities() {
            if (
                // Das Formular bricht ab, wenn zu viele PLZs in der Liste sind
                (this.eventSearchType == 'zip' && this.zipValue.length < 2)
            ) {
                return;
            }

            axios.get(this.activeRoute, {
                params: {
                    type: this.type,
                    filter: this.getActiveValue(),
                }
            }).then(response => {
                console.log('Werte geholt');
                console.log(this.activeRoute);
                console.log(this.getActiveValue());
                console.log(response.data);
                this.showOpportunities = true;
                this.opportunities = response.data;
            })
        },
        setSelectedValue(selected) {
            if (this.eventSearchType == 'city') {
                this.cityValue = selected;
            }

            if (this.eventSearchType == 'zip') {
                this.zipValue = selected;
            }

            this.showOpportunities = false;
        },
        setActiveRoute() {
            if (this.eventSearchType == 'city') {
                this.activeRoute = this.cityRoute;
            }
            if (this.eventSearchType == 'zip') {
                this.activeRoute = this.zipRoute;
            }
        },
        getActiveValue() {
            console.log('Aktiver Suchfilter ermitteln');
            console.log(this.eventSearchType);
            if (this.eventSearchType == 'city') {
                return this.cityValue;
            }

            if (this.eventSearchType == 'zip') {
                return this.zipValue;
            }

            return '';
        },
        handleResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        },
    },
    mounted() {
    }
};
</script>

<style>
#ort_list_id, #zip_list_id {
    list-style-type: none;
    padding-top: 1rem;
    padding-left: 1rem;
}

#ort_list_id li, #zip_list_id li {
    padding: 0.5rem 0.5rem 0.5rem 0;
    border-bottom: 1px solid grey;
}
</style>
