<template>
    <div class="input-group has-validation" v-if="width > 1000">
            <input
                :list="list"
                class="form-control col-12 form-control-lg"
                autocomplete="off"
                :placeholder="designation"
                :aria-label="designation"
                :name="fieldName"
                v-model="inputValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                :aria-describedby="response"
                :id="id"
            />
            <div
                :id="response"
                class="invalid-feedback"
            >
                Wir haben keinen entsprechenden Ort gespeichert.
            </div>
            <datalist
                :id="list"
            >
                <option v-for="opportunity in opportunities">
                    {{ opportunity }}
                </option>
            </datalist>
    </div>
    <div class="input-group has-validation" v-else-if="width <= 1000">
            <input
                class="form-control form-control-lg col-12"
                autocomplete="off"
                :placeholder="designation"
                :aria-label="designation"
                :name="fieldName"
                v-model="inputValue"
                @keyup="fetchOpportunities"
                @click="fetchOpportunities"
                :class="{ 'is-invalid': inputNotExistent }"
                :aria-describedby="response"
                :id="id"
            />
            <div
                :id="response"
                class="invalid-feedback"
            >
                Wir haben keinen entsprechenden Ort gespeichert.
            </div>
            <ul
                v-if="showOpportunities == true"
                id="input-search-mobile"
                class="col-12"
            >
                <li
                    v-for="opportunity in opportunities"
                >
                    <span @click="setSelectedValue(opportunity)">{{ opportunity }}</span>
                </li>
            </ul>
    </div>
</template>

<script>
export default {
    props: {
        handingOver: String,
        route: String,
        fieldName: String,
        designation: String,
    },
    data() {
        return {
            inputValue: this.handingOver,
            inputNotExistent: false,
            opportunities: null,
            id: this.fieldName + this.designation + '_id',
            list: this.fieldName + this.designation + '_list',
            response: this.fieldName + this.designation + '_response',
            width: 0,
            height: 0,
            showOpportunities: true,
        };
    },
    watch: {
        opportunities() {
            this.inputNotExistent = (this.opportunities.length === 0);
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    methods: {
        fetchOpportunities() {
            if (
                this.inputValue === null
                // || this.inputValue.length < 1
            ) {
                return;
            }
            console.log('Sucht');
            axios.get(this.route, {
                params: {
                    filter: this.inputValue,
                }
            }).then(response => {
                console.log(response.data);
                this.showOpportunities = true;
                this.opportunities = response.data;
            })
        },
        setSelectedValue(selected) {
            this.inputValue = selected;
            this.showOpportunities = false;
        },
        handleResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        }
    },
    mounted() {
    }
};
</script>

<style>
#input-search-mobile {
    list-style-type: none;
    padding-top: 1rem;
    padding-left: 1rem;
}

#input-search-mobile li {
    padding: 0.5rem 0.5rem 0.5rem 0;
    border-bottom: 1px solid grey;
}
</style>
