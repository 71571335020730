require('./bootstrap');

import Vue from 'vue';
import InputSearch from './InputSearch.vue';
import CopyButton from './CopyButton.vue';
import OrganisationSearch from './OrganisationSearch.vue';
import EventSearch from './EventSearch.vue';

new Vue({
    el: '#haurio-app',
    data() {
        return {
            showPricingForYearlyContractDuration: false,
            showPricingForMonthlyContractDuration: true,
        }
    },
    components: { InputSearch, CopyButton, OrganisationSearch, EventSearch },
    watch: {
        showPricingForYearlyContractDuration() {
            this.showPricingForMonthlyContractDuration = !this.showPricingForYearlyContractDuration
        },
        showPricingForMonthlyContractDuration() {
            this.showPricingForYearlyContractDuration = !this.showPricingForMonthlyContractDuration
        }
    },
    mounted() {
    }
});
